<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <span class="system-version" @click="openModal" v-if="versions.code">{{
        versions.code
      }}</span>
    </div>

    <!-- Modal Notas de Versão -->
    <b-modal id="modalVersao" title="Notas da Versão" centered>
      <div class="table-responsive">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Título</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(issue, index) in versions.version_issues" :key="index">
              <td>
                {{ issue.issue.summary }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeModal">Fechar</b-button>
      </template>
    </b-modal>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- Bell Icon Button with Dropdown Template -->
      <b-nav-item-dropdown right class="d-sm-flex d-none bell-nav">
        <template #button-content>
          <b-link class="nav-link">
            <feather-icon icon="BellIcon" size="21" class="bell-icon" />
            <span class="notification-badge" v-if="hasUnreadNotifications">{{
              newNotificationCount
            }}</span>
          </b-link>
        </template>
      </b-nav-item-dropdown>

      <!-- Dropdown do usuário -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ user.name }}</p>
            <span class="user-status">{{ user.company_name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- Links do dropdown do usuário -->
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Meu Perfil</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider></b-dropdown-divider> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BModal,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Cookies from "js-cookie"; // Importa a biblioteca de cookies

export default {
  data() {
    return {
      versions: {
        version_issues: [],
      },
      user: {},
      notifications: [],
      newNotificationCount: 0,
      versionUpdateInterval: null,
      posts: [],
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BModal,

    // Componentes do Navbar
    DarkToggler,
  },
  computed: {
    hasUnreadNotifications() {
      return this.notifications.some(
        (notification) =>
          !notification.last_seen &&
          notification.user_id !== this.$store.state.user.id
      );
    },
  },
  methods: {
    toggleVerticalMenuActive() {
      // Implemente sua lógica de toggle do menu vertical aqui
    },
    openModal() {
      this.$bvModal.show("modalVersao"); // Mostra o modal usando o Bootstrap-Vue
    },
    closeModal() {
      this.$bvModal.hide("modalVersao"); // Oculta o modal usando o Bootstrap-Vue
    },
    logout() {
      let api = this.$store.state.api + "logout";

      axios
        .post(api)
        .then((response) => {
          localStorage.removeItem("token");
          this.$router.push({ path: "/login" });
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$message("Erro", "Erro ao realizar logout", "error");
        });
    },
    async getVersions() {
      const api = this.$store.state.api + "version_env/";

      try {
        const response = await axios.get(api);
        const newVersion = response.data.code;
        console.log("Versões obtidas:", response.data);

        const savedVersion = Cookies.get("current_version");
        if (savedVersion && savedVersion !== newVersion) {
          alert(
            "Uma nova versão está disponível. Por favor, atualize o sistema."
          );
        }

        if (savedVersion !== newVersion) {
          this.saveVersionInCookie(newVersion);
        }

        this.versions = response.data;
      } catch (error) {
        this.$message(null, error.response.data, "error");
      }
    },

    saveVersionInCookie(versionCode) {
      Cookies.set("current_version", versionCode, { expires: 7, path: "/" });
    },

    checkVersion() {
      const savedVersion = Cookies.get("current_version");
      if (savedVersion && savedVersion !== this.versions.code) {
        alert(
          "Uma nova versão está disponível. Por favor, atualize o navegador."
        );
      }
    },
    updateVersionCookie() {
      this.getVersions();
    },
    getNotifications() {
      const api = this.$store.state.api + "notifications/";

      axios
        .get(api)
        .then((response) => {
          this.notifications = response.data.data;
          this.newNotificationCount = this.notifications.length;
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    this.user = this.$store.state.user;
    this.getVersions();
    this.getNotifications();

    window.Echo.private("posts")
      .listen("NewPostEvent", (event) => {
        console.log("Evento NewPostEvent recebido:", event);
      })
      .error((error) => {
        console.error("Erro ao ouvir o evento:", error);
      });

    // this.$nextTick(() => {
    //   this.newNotificationCount = this.notifications.filter(
    //     (notification) =>
    //       !notification.last_seen &&
    //       notification.user_id !== this.$store.state.user.id
    //   ).length;
    // });

    this.versionUpdateInterval = setInterval(() => {
      this.updateVersionCookie();
    }, 3600000);
  },
  beforeDestroy() {
    clearInterval(this.versionUpdateInterval);
  },
};
</script>

<style scoped>
.system-version {
  font-weight: bold;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.25em 0.5em; /* Reduzi o padding para o texto ficar mais próximo do círculo */
  font-size: 0.9em; /* Aumentei o tamanho da fonte */
  font-weight: bold; /* Adicionei negrito para melhorar a visibilidade */
  min-width: 1em; /* Garante que o círculo tenha um tamanho mínimo */
  text-align: center; /* Centraliza o texto horizontalmente */
  line-height: 1; /* Adicione esta propriedade para garantir que o span ocupe espaço mesmo quando estiver vazio */
}
.unread-notification::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
